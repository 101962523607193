<template>
  <div>
    <p class="tab-description custom-description-width">
      <span>"Viewed Leads"</span> provides quick access to the leads whose contact details you’ve explored.
      Easily review, connect, and take action by adding promising leads to your retailer list for potential business opportunities.
    </p>
    <div
      v-if="isFetchingData"
      class="spinner-container"
    >
      <b-spinner label="Loading..." />
    </div>
    <div
      v-if="!isFetchingData && viewedLeads.length > 0"
      class="viewed-leads-grid"
    >
      <LeadAICard
        v-for="lead in viewedLeads"
        :key="lead.id"
        :lead="lead"
        :on-view-details="() => fetchLeadDetails(lead.id)"
      />
    </div>
    <div
      v-if="!isFetchingData && viewedLeads.length > 0"
      class="pagination-container"
    >
      <b-row>
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalViewedLeads"
            :per-page="pageSize"
            page-class="hidden"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            ellipsis-class="custom-styles"
            @change="handlePageChange"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <div
      v-if="!isFetchingData && viewedLeads.length === 0"
      class="no-leads-container"
    >
      <p class="no-leads-text">
        No viewed leads yet.
      </p>
      <small
        class="no-leads-subtext"
      >Check back soon once you view new leads.</small>
    </div>
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import {
 BSpinner, BPagination, BCol, BRow,
} from 'bootstrap-vue'
import LeadAICard from '../../LeadAICard.vue'
import { useViewedLeads } from './useViewedLeads'

export default {
  name: 'ViewedLeadsTab',
  components: {
    LeadAICard,
    BSpinner,
    BPagination,
    BCol,
    BRow,
  },
  setup() {
    const {
      viewedLeads,
      currentPage,
      pageSize,
      totalViewedLeads,
      isFetchingData,
      getViewedLeads,
      handlePageChange,
      fetchLeadDetails,
    } = useViewedLeads()

    onMounted(() => {
      getViewedLeads()
    })

    return {
      viewedLeads,
      totalViewedLeads,
      fetchLeadDetails,
      isFetchingData,
      handlePageChange,
      currentPage,
      pageSize,
    }
  },
}
</script>

<style scoped>
.viewed-leads-grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(178px, 227px));
  padding-left: 0.5rem;
  padding-right: 1rem;
  column-gap: 20px;
  row-gap: 10px;
}

@media (max-width: 1258px) {
  .viewed-leads-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(178px, 1fr));
  }
}
.no-leads-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  text-align: center;
}

.no-leads-text {
  font-size: 1.75rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}

.no-leads-subtext {
  font-size: 1rem;
  color: #666;
}

.custom-description-width {
  max-width: 900px;
}
</style>

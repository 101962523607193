import { apiToastError } from '@/@core/utils/toast'
import { useRouter } from '@/@core/utils/utils'
import store from '@/store'
import { GET_VIEWED_LEADS } from '@/store/modules/leadai.module'
import { ref } from '@vue/composition-api'

export const useViewedLeads = () => {
    const viewedLeads = ref([])
    const totalViewedLeads = ref(0)
    const currentPage = ref(1)
    const pageSize = 20
    const isFetchingData = ref(true)
    const { router } = useRouter()

    const getViewedLeads = async () => {
        try {
            const response = await store.dispatch(GET_VIEWED_LEADS, {
                pageNumber: currentPage.value,
                pageSize,
            })
            viewedLeads.value = response.data.data
            totalViewedLeads.value = response.data.totalCount
        } catch (err) {
            apiToastError(err)
        } finally {
            isFetchingData.value = false
        }
    }

    const fetchLeadDetails = leadId => {
        router.push({ name: 'leads-gen-ai-detail', params: { leadId } })
    }

    const handlePageChange = async pageNumber => {
        currentPage.value = pageNumber
        await getViewedLeads()
    }

    return {
        viewedLeads,
        getViewedLeads,
        totalViewedLeads,
        fetchLeadDetails,
        isFetchingData,
        currentPage,
        handlePageChange,
        pageSize,
    }
}

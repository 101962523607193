<template>
  <form @submit.prevent>
    <div class="wrapper">
      <p class="section-title">
        Tell us about you
      </p>
      <b-row class="px-1">
        <b-col
          md="6"
          class="left-col"
        >
          <b-form-group
            class="custom-form-group-styles"
            label="Website *"
            label-class="w-fit-content custom-label-style"
          >
            <b-form-input
              :value="website"
              :state="websiteErrorState"
              placeholder="Your website url like www.example.com"
              @keydown.space.prevent
              @paste="removeSpaces"
              @input="handleWebsiteChange"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="right-col"
        >
          <b-form-group class="position-up">
            <span class="custom-label-style">Average retail price, $</span>
            <custom-select
              id="avg-retail-price-dropdown"
              v-model="avgRetailPrices"
              :is-searchable="false"
              :should-scroll-on-focus="true"
              multiple
              show-arrow-icon
              placeholder="e.g. 50-100, 100-200"
              no-data-text="No price range found."
              theme="grey"
              :option-groups="avgRetailPricesOptions"
              size="small"
              @search="handleSearchRetailPrices"
              @select="(prices) => avgRetailPrices = prices"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="px-1 pb-1">
        <b-col
          md="6"
          class="left-col"
        >
          <b-form-group
            class="custom-form-group-styles"
            label="Target end customer profile"
            label-class="w-fit-content custom-label-style"
          >
            <b-form-textarea
              v-model="targetEndCustomerProfile"
              type="textarea"
              rows="4"
              max-rows="4"
              no-resize
              placeholder="Who are your products designed for? Include demographics like age, gender, income level, and interests (e.g., men, 18-24 years old, athletes, middle-income)"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="right-col"
        >
          <b-form-group
            class="custom-form-group-styles"
            label="Partnership objectives"
            label-class="w-fit-content custom-label-style"
          >
            <b-form-textarea
              v-model="partnershipObjectives"
              type="textarea"
              rows="4"
              max-rows="4"
              no-resize
              placeholder="What are your business objectives for collaboration with desired leads? (e.g., strengthening brand positioning, accessing new customer segments, or scaling distribution channels)."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <p class="section-title">
        Tell us about your ideal leads
      </p>
      <b-row class="px-1">
        <b-col
          class="left-col"
          md="6"
        >
          <b-form-group
            class="tag-form-group"
            label-class="w-fit-content custom-label-style"
          >
            <template #label>
              Lookalike leads domains * <span class="grey-label-text">(comma separated domains, 10 max)</span>
            </template>
            <b-form-tags
              v-model="lookalikeLeads"
              separator=","
              tag-class="custom-tag"
              remove-on-delete
              :state="lookalikeLeadsErrorState"
              duplicate-tag-text="Duplicate lead domain"
              invalid-tag-text=""
              :limit="10"
              limit-tags-text=""
              :placeholder="!lookalikeLeads.length ? `Share the domains of businesses that are similar to your target leads` : ''"
              input-id="lookalikeLeadInput"
              @input="handleLookalikeLeadsChange"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="right-col"
        >
          <b-form-group
            class="tag-form-group"
            label-class="w-fit-content custom-label-style"
          >
            <template #label>
              Desired industries <span class="grey-label-text">(comma separated words)</span>
            </template>
            <b-form-tags
              v-model="desiredIndustries"
              separator=","
              tag-class="custom-tag"
              remove-on-delete
              duplicate-tag-text="Duplicate industry name"
              :placeholder="!desiredIndustries.length ? `Specify the industries you want to target for your leads` : ''"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="px-1">
        <b-col
          md="6"
          class="left-col"
        >
          <b-form-group
            class="tag-form-group"
            label-class="w-fit-content custom-label-style"
          >
            <template #label>
              Desired keywords describing the leads <span class="grey-label-text">(comma separated words, 10 max)</span>
            </template>
            <b-form-tags
              v-model="desiredKeywords"
              separator=","
              tag-class="custom-tag"
              :limit="10"
              limit-tags-text=""
              remove-on-delete
              duplicate-tag-text="Duplicate keyword"
              :placeholder="!desiredKeywords.length ? `List specific terms that define your target leads' attributes or focus` : ''"
              input-id="desiredKeywordsInput"
              @input="(val) =>handleFormTagLimit(val, 'desiredKeywordsInput')"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="right-col"
        >
          <b-form-group
            class="tag-form-group"
            label-class="w-fit-content custom-label-style"
          >
            <template #label>
              Keywords to exclude from the leads description <span class="grey-label-text">(comma separated words, 10 max)</span>
            </template>
            <b-form-tags
              v-model="excludeKeywords"
              separator=","
              tag-class="custom-tag"
              remove-on-delete
              duplicate-tag-text="Duplicate keyword"
              :placeholder="!excludeKeywords.length ? `Specify terms to exclude from the description of your target leads` : ''"
              :limit="10"
              limit-tags-text=""
              input-id="excludeKeywordInput"
              @input="(val) =>handleFormTagLimit(val, 'excludeKeywordInput')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="px-1">
        <b-col
          md="6"
          class="left-col"
        >
          <b-form-group class="position-up">
            <span class="custom-label-style">Desired countries</span>
            <custom-select
              id="desired-countries-dropdown"
              v-model="desiredCountries"
              multiple
              show-arrow-icon
              placeholder="Enter the countries where you want to focus your lead search"
              no-data-text="No country found."
              theme="grey"
              size="small"
              :should-scroll-on-focus="true"
              :option-groups="desiredCountriesOptions"
              @search="handleSearchCountries"
              @select="(countries) => desiredCountries = countries"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="right-col"
        >
          <b-form-group
            class="custom-form-group-styles"
            label="Additional comments"
            label-class="w-fit-content custom-label-style"
          >
            <b-form-input
              v-model="additionalComments"
              placeholder="Add any comments or notes that can help AI tailor the results to your needs"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div
        class="w-100 d-flex"
        :class="errorMsg ? `justify-content-between` : `justify-content-end`"
      >
        <p
          v-if="errorMsg"
          class="error-text"
        >
          {{ errorMsg }}
        </p>
        <b-button
          :disabled="isFormLoading"
          variant="light"
          class="submit-btn"
          @click.prevent="handleFormSubmit"
        >
          <b-spinner
            v-if="isFormLoading"
            class="mr-1"
            small
            label="Loading..."
          />
          <span v-if="isFormLoading">Loading...</span>
          <span v-else>Get tailored leads</span>
        </b-button>
      </div>
    </div>
  </form>
</template>
<script>
import {
 BFormInput, BFormGroup, BCol, BRow, BFormTextarea, BButton, BFormTags, BSpinner,
} from 'bootstrap-vue'
import CustomSelect from '@/@core/components/custom-select/CustomSelect.vue'
import store from '@/store'
import { GET_COUNTRIES } from '@/store/modules/common.module'
import { validatorUrl } from '@/@core/utils/validations/validators'
import { isEqual } from 'lodash'
import { SET_TAILORED_LEADS_FORM_DATA } from '@/store/modules/leadai.module'

export default {
  name: 'TailoredLeadsForm',
  components: {
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    CustomSelect,
    BButton,
    BFormTags,
    BSpinner,
  },
  props: {
    isFormLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      website: '',
      avgRetailPrices: [],
      avgRetailPricesOptions: [],
      targetEndCustomerProfile: '',
      partnershipObjectives: '',
      desiredCountries: [],
      desiredKeywords: [],
      lookalikeLeads: [],
      desiredIndustries: [],
      excludeKeywords: [],
      additionalComments: '',
      desiredCountriesOptions: [],
      allAvgRetailPrices: [
        'Under 50',
        '51 - 100',
        '101 - 150',
        '151 - 200',
        '250 - 300',
        '> 300',
      ],
      previousPayload: {},
      errorFlags: {},
      allCountries: [],
      errorMsg: '',
      validatorUrl,
    }
  },
  computed: {
    websiteErrorState() {
      if (!this.errorFlags?.website) {
        return null
      }
      return this.errorFlags.website.required !== null || this.errorFlags.website.invalid !== null ? false : null
    },
    lookalikeLeadsErrorState() {
      if (!this.errorFlags?.lookalikeLeads) {
        return null
      }
      return this.errorFlags?.lookalikeLeads?.required !== null || this.errorFlags.lookalikeLeads.invalid !== null ? false : null
    },
    isDemoUser() {
      return store.getters.isDemo
    },
  },
  watch: {
    errorFlags(val) {
      this.updateErrorMsg(this.getErrorFlags(val))
    },
  },
  created() {
    this.getAllCountries()
    this.avgRetailPricesOptions = [
      {
        title: '',
        options: this.allAvgRetailPrices,
      },
    ]

    const existingData = store.state.leadai.tailoredLeadsFormData

    if (existingData) {
      this.website = existingData.website
      this.avgRetailPrices = existingData.avgRetailPrices
      this.targetEndCustomerProfile = existingData.targetEndCustomerProfile
      this.partnershipObjectives = existingData.partnershipObjectives
      this.desiredCountries = existingData.desiredCountries
      this.desiredKeywords = existingData.desiredKeywords
      this.lookalikeLeads = existingData.lookalikeLeads
      this.desiredIndustries = existingData.desiredIndustries
      this.excludeKeywords = existingData.excludeKeywords
      this.additionalComments = existingData.additionalComments
    }
  },
  mounted() {
    const label = document.getElementsByClassName('desired-country-label')
    if (label?.length) {
      label[0].addEventListener('click', e => e.preventDefault())
    }
  },
  methods: {
    allowOnlyBackspace(e) {
      if (e.key !== 'Backspace') {
        e.preventDefault()
      }
    },
    isWebsiteErrorAllowed(flag) {
      return this.websiteErrorState !== null && flag !== null
    },
    isLeadsErrorAllowed(flag) {
      return this.lookalikeLeadsErrorState !== null && flag !== null
    },
    getErrorFlags(val) {
      return {
        websiteRequired: this.isWebsiteErrorAllowed(val.website?.required),
        invalidUrl: this.isWebsiteErrorAllowed(val.website?.invalid),
        leadsRequired: this.isLeadsErrorAllowed(val.lookalikeLeads?.required),
        invalidLeads: this.isLeadsErrorAllowed(val.lookalikeLeads?.invalidLeads),
      }
    },
    async handleWebsiteChange(val) {
      this.website = val
      this.errorFlags = {
        ...this.errorFlags,
        website: {
          required: !val ? false : null,
          invalid: !validatorUrl(val) ? false : null,
        },
      }
    },
    async handleLookalikeLeadsChange(val) {
      this.errorFlags = {
        ...this.errorFlags,
        lookalikeLeads: {
          required: val.length === 0 ? false : null,
          invalid: val.length !== 0 && !val.every(tag => validatorUrl(tag)) ? false : null,
        },
      }
      await this.handleFormTagLimit(val, 'lookalikeLeadInput')
    },
    async handleFormTagLimit(val, inputId) {
      const inputEl = document.getElementById(inputId)
      if (!inputEl) {
        return
      }
      if (val.length >= 10) {
        inputEl.addEventListener('keydown', this.allowOnlyBackspace)
      } else {
        inputEl.removeEventListener('keydown', this.allowOnlyBackspace)
        inputEl.focus()
      }
    },
    getAllCountries() {
      store.dispatch(GET_COUNTRIES).then(res => {
        this.allCountries = res.data.data.COUNTRIES
        this.desiredCountriesOptions = [{
          title: '',
          options: this.allCountries,
        }]
      })
    },
    removeSpaces(e) {
      e.preventDefault()
      const left = e.target.value.substring(0, e.target.selectionStart)
      const right = e.target.value.substring(e.target.selectionEnd, e.target.value.length)
      const pasted = (e.dataTransfer || e.clipboardData).getData('text').replace(/ /g, '')
      const val = left + pasted + right
      e.target.value = val
      this.handleWebsiteChange(val)
    },
    handleSearchRetailPrices(searchString) {
      this.avgRetailPricesOptions = [
        {
          title: '',
          options: this.allAvgRetailPrices.filter(range => range.toLowerCase()
          .indexOf(searchString.toLowerCase()) !== -1),
        },
      ]
    },
    handleSearchCountries(searchString) {
      this.desiredCountriesOptions = [
        {
          title: '',
          options: this.allCountries.filter(country => country.toLowerCase()
          .indexOf(searchString.toLowerCase()) !== -1),
        },
      ]
    },
    async handleFormSubmit() {
      const isValid = this.validate()

      const payload = {
        website: this.website,
        avgRetailPrices: this.avgRetailPrices,
        targetEndCustomerProfile: this.targetEndCustomerProfile,
        partnershipObjectives: this.partnershipObjectives,
        desiredCountries: this.desiredCountries,
        desiredKeywords: this.desiredKeywords,
        lookalikeLeads: this.lookalikeLeads,
        desiredIndustries: this.desiredIndustries,
        excludeKeywords: this.excludeKeywords,
        additionalComments: this.additionalComments,
      }

      if (isValid) {
        if (!isEqual(this.previousPayload, payload)) {
          store.commit(SET_TAILORED_LEADS_FORM_DATA, {
            ...payload,
          })
          this.$emit('form-submit', payload)
          this.previousPayload = payload
        } else {
          this.$emit('duplicate-search')
        }
      }
    },
    validate() {
      const websiteRequired = !this.website
      const leadsRequired = this.lookalikeLeads.length === 0
      const invalidUrl = !validatorUrl(this.website)
      const invalidLeads = !this.lookalikeLeads.every(lead => validatorUrl(lead))

      this.errorFlags = {
        website: {
          required: websiteRequired ? false : null,
          invalid: invalidUrl ? false : null,
        },
        lookalikeLeads: {
          required: leadsRequired ? false : null,
          invalid: invalidLeads ? false : null,
        },
      }

      return !websiteRequired && !invalidUrl && !leadsRequired && !invalidLeads
    },
    updateErrorMsg(flags) {
      const {
        websiteRequired, leadsRequired, invalidLeads, invalidUrl,
      } = flags

      const conditionsToMessages = [
        { condition: websiteRequired && leadsRequired, message: 'Please fill in your "Website url" and "Lookalike leads domains" fields.' },
        { condition: websiteRequired && invalidLeads, message: 'Please fill in your "Website url" and enter valid "Lookalike lead domains" field.' },
        { condition: invalidUrl && leadsRequired, message: 'Please enter valid "Website url" and fill "Lookalike leads domains" field.' },
        { condition: invalidUrl && invalidLeads, message: 'Please enter valid "Website url" and "Lookalike lead domains" fields.' },
        { condition: websiteRequired, message: 'Please fill in your "Website url" field.' },
        { condition: leadsRequired, message: 'Please fill "Lookalike leads domains" field.' },
        { condition: invalidUrl, message: 'Please enter valid "Website url" field.' },
        { condition: invalidLeads, message: 'Please enter valid "Lookalike leads domains" field.' },
      ]
      for (const conditionItem of conditionsToMessages) {
        if (conditionItem.condition) {
          this.errorMsg = conditionItem.message
          return
        }
      }
      this.errorMsg = ''
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes

.wrapper {
  background-color: #fff;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  padding: 16px 24px;
  padding-top: 26px;
  max-width: 70.4vw;

  .error-text {
    color: #D60909;
    font-weight: 500;
    max-width: 600px;
  }

  .left-col {
    padding: 0;
    padding-right: 1rem;
  }

  .right-col {
    padding: 0;
    padding-left: 1rem;
  }

  .section-title {
    font-size: 16px;
    font-weight: bold;
  }

  .custom-label-style {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 3px;
    margin-bottom: 0;
  }

  .w-fit-content {
    width: fit-content !important;
  }

  .custom-form-group-styles {
    input {
      font-size: 12px;
      padding: 10px 9px !important
    }

    textarea {
      font-size: 12px;
      padding: 9px 8px !important
    }
  }

  input:placeholder-shown {
    text-overflow: ellipsis;
  }

  .position-up {
    position: relative;
    top: -2px
  }

  .tag-form-group {
    input {
      font-size: 12px;
    }

    .grey-label-text {
      width: fit-content;
      display: inline;
      font-size: inherit;
      color: $lighter-gray;
    }
    .form-control {
      padding-top: 5px;
      padding-bottom: 5px;

      .b-form-tags-input {
        min-height: 26px;
      }

      .b-form-tags-button {
        display: none;
      }
    }
    .custom-tag {
      color: $primary !important;
      font-weight: 500;
      font-size: 12px;
      display: flex !important;
      justify-content: space-between;
      background-color: $bright-gray !important;
      border-radius: 3px;
      align-items: center !important;
      margin-right: 7px;

      button.b-form-tag-remove {
        align-self: end;
      }
    }
  }

  .submit-btn {
    background-color: $custom-secondary !important;
    color: $primary !important;
    font-weight: 400;
    width: 234px;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .wrapper {
    .left-col, .right-col {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

</style>
